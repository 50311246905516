<template>
  <div>
    <div class="mb-6 mt-8" style="width: 50%">
      <h3>
        <span>Import Loading Area</span>
      </h3>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (xls, xlsx)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="file"
          ref="fileInput"
          accept=".xlsx, .xls"
          @change="handleChangeFile"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-input-group flex space-x-3">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleImport()"
        >Import</vs-button
        >
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-download"
          @click="handleDownloadTemplate()"
        >Template Download</vs-button
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Import">
            <data-table-log-import
              draw="draw"
              @logImportLine="handleLogImportLine"
            ></data-table-log-import>
          </vs-tab>
          <vs-tab label="Import Line">
            <data-table-log-import-line
              :logImportID="this.logImportID"
            >
            </data-table-log-import-line>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DataTableLogImport from "./Table.vue";
import DataTableLogImportLine from "./Detail.vue";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
export default {
  components: {
    DataTableLogImport,
    DataTableLogImportLine,
    Datepicker,
    vSelect,
  },

  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      activeTab: 0,

      month: null,

      file: null,
      draw: 0,
      logImportID: 0,
    };
  },

  methods: {
    setDefault() {
      this.activeTab = 0;
      this.month = null;
      this.file = null;
      this.logImportID = 0;
      this.$refs.fileInput.value = null;
    },
    handleLogImportLine(id) {
      this.activeTab = 1;
      this.logImportID = id;
    },
    handleDownloadTemplate() {
      this.$vs.loading();
      var fileTitle = "WAREHOUSE_AREA_LOADING_AREA_LOADING_AREA_IMPORT_MASTER_TEMPLATE "+moment().format("YYYY-MM-DD");

      this.$http
        .get("/api/wms/v1/master-import/warehouse-area-loading-area-import-template",{
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream"
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error"){
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        })
    },

    handleImport() {
      if (!this.file) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let formData = new FormData();
      formData.append("file", this.file);

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/master-import/warehouse-area-loading-area-import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log(resp)
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
            this.draw++;
            this.setDefault();
          }else {
            console.log(resp)
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },

    handleChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null ;
      if (this.file) {
        const reader = new FileReader();

        reader.readAsBinaryString(this.file);
      }
    }
  }
}
</script>
