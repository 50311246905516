<template>
  <vx-card>
    <vs-tabs :value="active">
      <vs-tab label="Storage Area">
        <StorageArea></StorageArea>
      </vs-tab>
      <vs-tab label="Loading Area">
        <LoadingArea></LoadingArea>
      </vs-tab>
      <vs-tab label="Special Area">
        <SpecialArea></SpecialArea>
      </vs-tab>
      <vs-tab label="Stagging Area">
        <StaggingArea></StaggingArea>
      </vs-tab>
      <vs-tab label="In-Transit Area">
        <InTransitArea></InTransitArea>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>

import StorageArea from "./storage-area/index.vue";
import LoadingArea from "./loading-area/index.vue";
import SpecialArea from "./special-area/index.vue";
import StaggingArea from "./stagging-area/index.vue";
import InTransitArea from "./in-transit-area/index.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    StorageArea,
    LoadingArea,
    SpecialArea,
    StaggingArea,
    InTransitArea,
  },
  data() {
    return {
      title: "Warehouse Area",
    };
  },
};
</script>
